import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logReducer, { logListenerMiddleware } from 'features/log';
import { emptySplitApi } from 'services/baseApi';

export const rootReducer = combineReducers({
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  log: logReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['log/configLog'],
        ignoredPaths: ['log.auditLog'],
      },
    })
      .prepend(logListenerMiddleware.middleware)
      .concat(emptySplitApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
