import {
  useFeatureToggleV2 as featureToggleV2,
  useFeatureToggleServiceV2,
} from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';
import { FeatureTogglesMap, featureToggles } from 'config/featureToggles';
import { useMemo } from 'react';

interface FeatureToggles extends FeatureTogglesMap {
  isSomeToggleLoading: boolean;
}

const useGetStoreToggles = () => {
  const service = useFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME);
  const store = service.getStore();
  return useMemo(() => store.getState(), []);
};

const togglesLoading = Object.keys(featureToggles).map(() => true);

const togglesInitialState = Object.keys(featureToggles).reduce(
  (all, toggle) => ({
    ...all,
    [toggle]: false,
  }),
  {} as FeatureTogglesMap
);

export const useFeatureToggles = (): FeatureToggles => {
  const storeToggles = useGetStoreToggles();

  const toggles = Object.entries(featureToggles).reduce((all, [toggle, toggleKey], index) => {
    if (storeToggles[toggleKey]) {
      togglesLoading[index] = false;
      return {
        ...all,
        [toggle]: storeToggles[toggleKey].value,
      };
    }
    const [isToggleActive, isToggleLoading] = featureToggleV2(toggleKey, OPTIMIZELY_PROJECT_NAME);
    togglesLoading[index] = isToggleLoading;
    return {
      ...all,
      [toggle]: !isToggleLoading && !!isToggleActive,
    };
  }, togglesInitialState);

  return {
    ...toggles,
    isSomeToggleLoading: togglesLoading.some((loading) => loading),
  };
};
