import { Dictionary } from 'lodash';
import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import { GenerateFormImageProps, GenerateFormImageReturn } from './types';

export const removeEmptyValuesFromObject = <T>(object: Dictionary<T>) =>
  pickBy<T>(object, (value) => {
    if (typeof value === 'string') return value.trim() !== '';
    return value !== null && value !== undefined;
  });

export const getDifferentKeys = <T extends object>(initialObj: T, alteredObj: T) => {
  const changes: string[] = [];

  const findChanges = (obj1: any, obj2: any, prefix: string = '') => {
    forOwn(obj1, (value, key) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (isObject(value) && isObject(obj2[key])) {
        findChanges(value, obj2[key], newKey);
      } else if (!isEqual(value, obj2[key])) {
        changes.push(newKey);
      }
    });

    forOwn(obj2, (_value, key) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (!(key in obj1)) {
        changes.push(newKey);
      }
    });
  };

  findChanges(initialObj, alteredObj);
  return changes;
};

export const generateFormImage = async <T>({
  form,
  name,
  generate,
  value,
}: GenerateFormImageProps<T>): Promise<GenerateFormImageReturn> => {
  if (value === 'changed' && generate) {
    const url = await generate();
    form.setFieldValue(name, url);
    return [{ name, url }];
  }
  return [];
};
