import { Countries } from 'domains/Countries';

// API
export const API_BASE_PATH = '/api';
export const EU_COUNTRIES: Countries[] = [
  Countries.ES,
  Countries.PH,
  Countries.ID,
  Countries.BE,
  Countries.NL,
  Countries.DE,
  Countries.GB,
];
export const ONE_STORE_MANAGEMENT_BFF_BASE_URL = '/one-store-management-bff';
export const COMPANY_MANAGEMENT_BFF_BASE_URL = '/company-management-bff';
export const ADMIN_PORTAL_SHARED_BFF_BASE_URL = '/v1/admin-portal-shared-bff';

// Optimizely
export const OPTIMIZELY_PROJECT_NAME = 'one-store-management-mfe';

// Audit Log
export const APP_ID = 'COMPANYMANAGEMENT';
export const STORES_ENTITY = 'STORES';
export const UPDATE_OPERATION = 'UPDATE';
export const INSERT_OPERATION = 'INSERT';
export const SORT_ORDER_DESCENDENT = 'DESC';

// General
export const DEFAULT_LANGUAGE = 'en-US';
export const DEFAULT_PAGE_SIZE = 10;

// Segment Analytics
export const SEGMENT_ANALYTICS_APP_NAME = 'COMPANY_PROFILE_SETUP';
export const SEGMENT_ANALYTICS_PERMISSION_GROUP = 'AdminPortalVendor';
export const SEGMENT_VIEW_LEVEL = 'STORE';
