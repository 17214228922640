/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Countries } from 'domains/Countries';
import { VendorStoreToUpdate } from 'hooks/queries/usePatchAllVendors';
import { AssociateDissociateMetadata, EditMetadata, useAuditLog } from 'hooks/useAuditLog';

export enum EndpointsToLog {
  updateVendorStores = 'updateVendorStores',
  updateStore = 'updateStore',
  createStore = 'createStore',
}

interface BaseLog {
  country: Countries;
}

interface LogCreateData {
  storeId: string;
  storeName: string;
}

export interface LogCreateStore extends BaseLog {
  endpoint: EndpointsToLog.createStore;
  data: LogCreateData;
}

interface LogAssociateDissociateData {
  vendor: AssociateDissociateMetadata['vendor'];
  stores: VendorStoreToUpdate[];
}

export interface LogUpdateVendorStores extends BaseLog {
  endpoint: EndpointsToLog.updateVendorStores;
  data: LogAssociateDissociateData;
}

interface LogEditData {
  storeId: string;
  storeName: string;
  fields: EditMetadata['fields'];
}

export interface LogUpdateStore extends BaseLog {
  endpoint: EndpointsToLog.updateStore;
  data: LogEditData;
}

export type LogData = LogCreateStore | LogUpdateVendorStores | LogUpdateStore;

type LogConfig = {
  auditLog: ReturnType<typeof useAuditLog>;
};

export const logSlice = createSlice({
  name: 'log',
  initialState: {} as LogConfig & LogData,
  reducers: {
    startLog: (state, { payload }: PayloadAction<LogData>) => {
      state.endpoint = payload.endpoint;
      state.data = payload.data;
      state.country = payload.country;
    },
    configLog: (state, { payload }: PayloadAction<LogConfig>) => {
      state.auditLog = payload.auditLog;
    },
  },
});

export default logSlice.reducer;
export const { startLog, configLog } = logSlice.actions;
