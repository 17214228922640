import { createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ActionType } from 'hooks/useAuditLog';
import {
  EndpointsToLog,
  LogCreateStore,
  LogData,
  LogUpdateStore,
  LogUpdateVendorStores,
  startLog,
} from './log';

export const logListenerMiddleware = createListenerMiddleware();

logListenerMiddleware.startListening({
  actionCreator: startLog,
  effect: (action, listenerApi) => {
    const { auditLog } = (listenerApi.getState() as RootState).log;

    const logger = {
      [EndpointsToLog.createStore]: ({ data: { storeId, storeName }, country }: LogCreateStore) => {
        auditLog({
          country,
          storeId,
          storeName,
          metadata: {
            action: ActionType.CREATE,
          },
        });
      },
      [EndpointsToLog.updateVendorStores]: ({
        data: { vendor, stores },
        country,
      }: LogUpdateVendorStores) => {
        stores?.forEach(({ id: storeId, name: storeName, associated }) => {
          auditLog({
            country,
            storeId,
            storeName,
            metadata: {
              action: associated ? ActionType.ASSOCIATE : ActionType.DISSOCIATE,
              vendor,
            },
          });
        });
      },
      [EndpointsToLog.updateStore]: ({
        data: { storeId, storeName, fields },
        country,
      }: LogUpdateStore) => {
        auditLog({
          country,
          storeId,
          storeName,
          metadata: {
            action: ActionType.EDIT,
            fields,
          },
        });
      },
    } as {
      [key in EndpointsToLog]: (log: LogData) => void;
    };

    logger[action.payload.endpoint]?.(action.payload);
  },
});
