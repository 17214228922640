import {
  useAuthenticationService as authenticationService,
  useLogService,
  useAuditLog as useOriginalAuditLog,
} from 'admin-portal-shared-services';
import { APP_ID, INSERT_OPERATION, STORES_ENTITY, UPDATE_OPERATION } from 'config/constants';
import { Countries } from 'domains/Countries';
import { UpdateStoreParams } from 'services/stores';

export enum ActionType {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  ASSOCIATE = 'ASSOCIATE',
  DISSOCIATE = 'DISSOCIATE',
}

interface BaseMetadata {
  userName: string;
  storeName: string;
}

export interface CreateMetadata {
  action: ActionType.CREATE;
}

export interface AssociateDissociateMetadata {
  action: ActionType.ASSOCIATE | ActionType.DISSOCIATE;
  vendor: {
    id: string;
    name: string;
  };
}

export interface EditMetadata {
  action: ActionType.EDIT;
  fields: Partial<
    Pick<UpdateStoreParams, 'name' | 'status' | 'image' | 'configurations'> & {
      defaultVendor: {
        id: string;
        name: string;
      };
    }
  >;
}

export interface AuditLogProps {
  storeId: string;
  storeName: string;
  country: Countries;
  metadata: CreateMetadata | AssociateDissociateMetadata | EditMetadata;
}

const stringifyMetadata = (metadata: AuditLogProps['metadata']) => {
  return Object.entries(metadata).reduce((obj, [key, value]) => {
    const newObj = { ...obj };
    newObj[key] = typeof value !== 'string' ? JSON.stringify(value) : value;
    return newObj;
  }, {} as Record<string, string>);
};

export const getBaseMetadata = (storeName: string): BaseMetadata => {
  const authService = authenticationService();
  const fullName = authService.getUserFullNameB2C();
  const userName = authService.parseJwt()?.name ?? `${fullName.given_name} ${fullName.family_name}`;
  return {
    userName,
    storeName,
  };
};

export const useAuditLog = () => {
  const audLog = useOriginalAuditLog(APP_ID);
  const log = useLogService();

  const auditLog = ({ storeId, storeName, country, metadata }: AuditLogProps) => {
    const maxRetries = 3;

    const operation = metadata.action === ActionType.CREATE ? INSERT_OPERATION : UPDATE_OPERATION;

    const baseMetadata = getBaseMetadata(storeName);
    const stringifiedMetadata = stringifyMetadata({ ...metadata, ...baseMetadata });

    const attemptLog = (attempt: number) => {
      audLog({
        metadata: stringifiedMetadata,
        operation,
        entity: STORES_ENTITY,
        entityId: storeId,
        country,
      }).catch((error) => {
        if (attempt < maxRetries) {
          const errorMessage = error.response?.data?.message || error.message;
          log.error(
            `Failed to log audit - Attempt ${
              attempt + 1
            }/${maxRetries} | Error: "${errorMessage}" | Metadata: ${JSON.stringify({
              ...metadata,
              storeId,
              storeName,
            })}`
          );
          attemptLog(attempt + 1);
        }
      });
    };

    attemptLog(0);
  };

  return auditLog;
};
