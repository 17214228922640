import { EndpointsToLog, LogData } from 'features/log/log';
import get from 'lodash/get';
import set from 'lodash/set';
import { UpdateVendorStoresParams } from 'services/company';
import { CreateStoreParams, CreateStoreResponse, UpdateStoreParams } from 'services/stores';

interface BaseLog {
  data: unknown;
  responseData?: unknown;
  extraData?: unknown;
}

interface LogCreateStoreEndpoint extends BaseLog {
  endpoint: EndpointsToLog.createStore;
  data: Pick<CreateStoreParams, 'name'>;
  responseData: Pick<CreateStoreResponse, 'id'>;
}

interface LogUpdateVendorStoresEndpoint extends BaseLog {
  endpoint: EndpointsToLog.updateVendorStores;
  data: UpdateVendorStoresParams['stores'];
  extraData: UpdateVendorStoresParams['logData'];
}

interface LogUpdateStoreEndpoint extends BaseLog {
  endpoint: EndpointsToLog.updateStore;
  data: Omit<UpdateStoreParams, 'logData'>;
  extraData: UpdateStoreParams['logData'];
}

const endpointMapper = {
  [EndpointsToLog.createStore]: ({ data, responseData }: LogCreateStoreEndpoint) => ({
    storeId: responseData.id,
    storeName: data.name,
  }),
  [EndpointsToLog.updateVendorStores]: ({ data, extraData }: LogUpdateVendorStoresEndpoint) => ({
    stores: data,
    vendor: {
      id: extraData.vendorId,
      name: extraData.vendorName,
    },
  }),
  [EndpointsToLog.updateStore]: ({ data, extraData }: LogUpdateStoreEndpoint) => ({
    storeName: extraData.storeName,
    storeId: extraData.storeId,
    fields: extraData.changedFields.reduce((acc, path) => {
      const value = get(data, path);
      if (value !== undefined) {
        if (path === 'defaultVendorId') {
          set(acc, 'defaultVendor', {
            id: value,
            name: extraData.defaultVendorName,
          });
          return acc;
        }
        set(acc, path, value);
      }
      return acc;
    }, {}),
  }),
} as {
  [key in EndpointsToLog]: (log: BaseLog) => LogData['data'];
};

export const getDataByEndpoint = ({
  endpoint,
  data,
  responseData,
  extraData,
}:
  | LogUpdateVendorStoresEndpoint
  | LogUpdateStoreEndpoint
  | LogCreateStoreEndpoint): LogData['data'] => {
  return endpointMapper[endpoint]?.({ data, responseData, extraData });
};
